import React from 'react'
import ReactCountryFlag from 'react-country-flag'
const TimelineItem = ({data}) => (
  <div className="timeline-item">
    <div className="timeline-item-content">
      <span className="tag" style={{background: data.category.color}}>
        {data.category.tag}
      </span>
      <time>{data.date}</time>
      <p>{data.text}</p>
      {data.link && (
        <a href={data.link.url} target="_blank" rel="noopener noreferrer">
          {data.link.text}
        </a>
      )}
      <ReactCountryFlag code={data.country ? data.country : 'ca'} svg />
      {data.footerLink && (
        <a href={data.footerLink.url} target="_blank" rel="noopener noreferrer">
          {data.footerLink.text}
        </a>
      )}
      <span className="circle" />
    </div>
  </div>
)

export default TimelineItem
