import {FaTag} from 'react-icons/fa/'
import PropTypes from 'prop-types'
import React from 'react'
import {graphql} from 'gatsby'
import {ThemeContext} from '../layouts'
import Article from '../components/Article/'
import Headline from '../components/Article/Headline'
import List from '../components/List'
import Seo from '../components/Seo'
import Timeline from '../components/Timeline'

const SchedulePage = props => {
  const {
    data: {
      posts: {edges: posts},
      site: {
        siteMetadata: {facebook},
      },
    },
  } = props

  // Create category list
  const categories = {}
  posts.forEach(edge => {
    const {
      node: {category},
    } = edge

    if (category && category != null) {
      if (!categories[category]) {
        categories[category] = []
      }
      categories[category].push(edge)
    }
  })

  const categoryList = []

  for (var key in categories) {
    categoryList.push([key, categories[key]])
  }

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
          <header>
            <Headline title="Weeks ahead" theme={theme} />
          </header>
          <Timeline theme={theme} />
          </Article>

        )}
        
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  )
}

SchedulePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SchedulePage

export const query = graphql`
  query TimelineQuery {
    posts: allContentfulPost(limit: 1000, sort: {fields: [date], order: DESC}) {
      edges {
        node {
          slug
          title
          date
          category
          author {
            name
          }
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          cover {
            fluid(maxWidth: 800, maxHeight: 360) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
