export default [
  {
    text: 'Bratislava ITF J2',
    date: 'Jan 06 2020',
    country: 'sk',
    category: {
      tag: 'itf',
      color: '#E17B77',
    },
    link: {
      url:
        'https://www.itftennis.com/en/tournament/j2-bratislava/svk/2020/j-g2-svk-01a-2020/',
      text: 'Tournament site',
    },
  },
  {
    text: 'Prague ITF J1',
    date: 'Jan 13 2020',
    country: 'cz',
    category: {
      tag: 'itf',
      color: '#E17B77',
    },
    link: {
      url:
        'https://www.itftennis.com/en/tournament/j1-prague-west/cze/2020/j-g1-cze-01a-2020/',
      text: 'Tournament site',
    },
  },
  {
    text: 'Sviatopetrivske Village ITF J1',
    date: 'Jan 20 2020',
    country: 'ua',
    category: {
      tag: 'itf',
      color: '#E17B77',
    },
    link: {
      url:
        'https://www.itftennis.com/en/tournament/j1-sviatopetrivske-village/ukr/2020/j-g1-ukr-01a-2020/',
      text: 'Tournament site',
    },
  },
  
  {
    text: 'Visiting family in Serbia',
    date: 'Jan 27 2020',
    country: 'rs',
    category: {
      tag: 'serbia',
      color: '#018f69',
    },
  },
  {
    text: 'Montreal - training in NTC',
    date: 'Feb 03 2020',
    category: {
      tag: 'ntc',
      color: '#1DA1F2',
    },
  },
  {
    text: 'Montreal - training in NTC',
    date: 'Feb 10 2020',
    category: {
      tag: 'ntc',
      color: '#1DA1F2',
    },
  },
  {
    text: 'Montreal - training in NTC',
    date: 'Feb 17 2020',
    category: {
      tag: 'ntc',
      color: '#1DA1F2',
    },
  },
  {
    text: 'Montreal - training in NTC',
    date: 'Feb 24 2020',
    category: {
      tag: 'ntc',
      color: '#1DA1F2',
    },
  },
  {
    text: 'Montreal - training in NTC',
    date: 'Mar 02 2020',
    category: {
      tag: 'ntc',
      color: '#1DA1F2',
    },
  },
  {
    text: 'Montreal - training in NTC',
    date: 'Mar 09 2020',
    category: {
      tag: 'ntc',
      color: '#1DA1F2',
    },
  },
  {
    text: 'Fredericton W25 Challenger',
    date: 'Mar 16 2020',
    category: {
      tag: 'challenger',
      color: '#FFDB14',
    },
    link: {
      url:
        "https://www.itftennis.com/en/tournament/w25-fredericton/can/2020/w-itf-can-01a-2020/",
      text: 'Tournament site',
    },
  },
  {
    text: 'Carson ITF J1',
    date: 'Mar 23 2020',
    country: 'us',
    category: {
      tag: 'itf',
      color: '#E17B77',
    },
    link: {
      url:
        'https://www.itftennis.com/en/tournament/j1-carson/usa/2020/j-g1-usa-01a-2020/',
      text: 'Tournament site',
    },
  }
]
