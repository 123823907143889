import React from 'react'
import './style.css'
import TimelineItem from './TimelineItem'
import timelineData from './data/'

export default () =>
  timelineData.length > 0 && (
    <div className="timeline-container">
      {timelineData.map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  )
